import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mail-confirmation-container" }
const _hoisted_2 = { class: "mail-confirmation-content" }
const _hoisted_3 = {
  key: 0,
  class: "loading-mail-confirmation"
}
const _hoisted_4 = {
  key: 1,
  class: "loading-mail-confirmation"
}
const _hoisted_5 = {
  key: 2,
  class: "loading-mail-confirmation"
}
const _hoisted_6 = {
  key: 3,
  class: "mail-confirmed"
}
const _hoisted_7 = { href: "/loginform" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageHeader, { defaultHeader: true }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('alprelax')), 1)
        ]),
        _: 1
      }),
      (_ctx.alreadyconfirmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('alreadyConfirmed')), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('redirectToLogin', {time: _ctx.countdown})), 1)
          ]))
        : (_ctx.unhandledError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('error.error')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('redirectToLogin', {time: _ctx.countdown})), 1)
            ]))
          : (!_ctx.confirmed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('header.loading')) + "...", 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('registrationFinished')) + ".", 1),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('regLoginNow')) + " ", 1),
                  _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('here')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('login')) + ".", 1)
                ])
              ]))
    ]),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error
    }, null, 8, ["show", "text", "error"])
  ]))
}